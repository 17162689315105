@import 'assets/fonts/horseshoes/horseshoes.scss';

$font-color: #263238;
$ketchup: #cc0000;
$mayo: #edd400;

@mixin breakpoint($size) {
  @if ($size == 'xs') {
    @media (max-width: 599px) { @content; }
  }
  @else if ($size == 'gt-xs') {
    @media (min-width: 600px) { @content; }
  }
  @else if ($size == 'sm') {
    @media (min-width: 600px) and (max-width: 959px) { @content; }
  }
  @else if ($size == 'gt-sm') {
    @media (min-width: 960px) { @content; }
  }
  @else if ($size == 'md') {
    @media (min-width: 960px) and (max-width: 1279px) { @content; }
  }
  @else if ($size == 'gt-md') {
    @media (min-width: 1280px) { @content; }
  }
  @else if ($size == 'lg') {
    @media (min-width: 1280px) and (max-width: 1919px) { @content; }
  }
  @else if $size == 'gt-lg' {
    @media (min-width: 1920px) { @content; }
  }
  @else if $size == 'xl' {
    @media (min-width: 1920px) { @content; }
  }
}

@mixin margin-bottom($size) {
  & > * {
    margin-bottom: $size;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

@mixin margin-right($size) {
  & > * {
    margin-right: $size;

    &:last-child {
      margin-right: 0px;
    }
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  display: inline-block;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

html, body, #root {
  height: 100%;
}

body {
  background: #fff;
  font-family: 'Horseshoes', serif;
  font-size: 24px;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: none;
}

.team {
  position: relative;
  display: inline-block;
  padding-left: 1.2em;
  font-family: 'Horseshoes And Lemonade', serif;
  font-size: 16px;
  overflow: hidden;

  &.ketchup {
    color: $ketchup;
  }

  &.mayo {
    color: $mayo;
  }

  .letter {
    font-size: 10em;
    line-height: .95em;
    letter-spacing: .05em;
  }

  .name {
    position: absolute;
    left: 0;
    bottom: 0;
    text-orientation: upright;
    writing-mode: vertical-rl;
  }
}

.teamsRoot {
  padding: 30px;
  text-align: center;

  .logo {
    margin-bottom: 120px;

    img {
      max-width: 160px;
    }
  }

  .choice {
    @include margin-bottom(15px);

    input {
      display: block;
      padding: 8px 15px;
      margin-left: auto;
      margin-right: auto;
      max-width: 325px;
      border: 1px solid $font-color;
      border-radius: 6px;
      outline: none;
    }

    .teams {
      display: flex;
      justify-content: center;
      align-items: center;
      @include margin-right(20px);

      @include breakpoint('xs') {
        @include margin-right(15px);
      }

      button {
        cursor: pointer;

        .team {
          font-size: 22px;

          @include breakpoint('xs') {
            font-size: 18px;
          }

          &:hover {
            animation: tilt-shaking 0.25s ease-in-out infinite;
          }
        }
      }
    }
  }
}

.buzzerRoot {
  background: #fff;
  padding: 30px;
  min-height: 100%;

  &.ketchup {
    background: $ketchup;
  }

  &.mayo {
    background: $mayo;
  }

  .meta {
    color: #fff;
    margin-bottom: 30px;

    .team {
      color: #fff;
    }

    .score {
      font-size: 32px;

      .value {
        font-family: 'Horseshoes And Lemonade', serif;
        font-size: 42px;
      }
    }
  }

  .buzzer {
    display: block;
    background: #fff;
    padding: 30px;
    margin: 0 auto;
    max-width: 325px;
    border-radius: 250px;
    transition: .25s;
    box-shadow: 0 10px 35px rgba(#000, .2);

    img {
      width: 100%;
      max-width: 100%;
    }

    &:not(:disabled) {
      &:active {
        transform: scale(.9);
        box-shadow: 0 5px 25px rgba(#000, .5);
      }
    }

    &:disabled {
      opacity: .4;
    }
  }
}

.remoteRoot {
  padding: 30px;
  min-height: 100%;

  @include breakpoint('gt-xs') {
    margin: 0 auto;
    max-width: 800px;

    .sections {
      display: flex;
      align-items: flex-start;
      @include margin-right(30px);

      & > * {
        flex: 1 1 50%;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 32px;
  }

  .subtitle {
    margin-top: 30px;
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .action {
      flex: 1 1 50%;
      padding: 10px;

      button {
        background: $font-color;
        display: block;
        font-family: 'Horseshoes', serif;
        color: #fff;
        font-size: 24px;
        text-align: center;
        width: 100%;
        transition: .25s;

        &:not(:disabled):hover {
          opacity: .7;
        }

        &:not(.multiline) {
          padding: 15px;

          &.lock {
            background: #d32f2f;
          }

          &.unlock {
            background: #388e3c;
          }
        }

        &.multiline {
          padding: 10px 15px;

          &.ketchup {
            background: $ketchup;
          }

          &.mayo {
            background: $mayo;
          }

          .increment {
            font-size: 30px;
          }

          .name {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.screenRoot {
  background: #000;
  height: 100%;

  .ratio {
    position: relative;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;

    &:before {
      display: block;
      padding-top: calc(9 / 16 * 100%);
      content: "";
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > .jingle {
    background: #0a090a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    height: 100%;
  }

  & > .buzzer {
    padding: 30px;
    height: 100%;

    &.ketchup {
      background: $ketchup;
    }

    &.mayo {
      background: $mayo;
    }

    .team {
      color: #fff;
      font-size: 3vh;
    }

    .player {
      margin-top: 30px;
      color: #fff;
      font-size: 10vh;
    }
  }

  & > .landing {
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;

    & > .logo {
      img {
        max-width: 160px;
      }
    }

    & > .content {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .title {
        font-size: 4vh;
        margin-bottom: 30px;

        .welcome {
          font-family: 'Horseshoes And Lemonade', serif;
          font-size: 8vh;
        }
      }

      img {
        max-width: 30vh;
      }
    }
  }

  & > .score {
    padding: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    @include margin-right(60px);

    .side {
      flex: 1 1 50%;
      text-align: center;

      .value {
        font-family: 'Horseshoes And Lemonade', serif;
        font-size: 25vh;

        &.ketchup {
          color: $ketchup;
        }

        &.mayo {
          color: $mayo;
        }
      }

      img {
        max-width: 80%;
        width: 100%;
      }
    }
  }
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
