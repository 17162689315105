@font-face {
    font-family: 'Horseshoes';
    src: url('./Horseshoes.woff2') format('woff2'),
        url('./Horseshoes.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Horseshoes and Lemonade';
    src: url('./HorseshoesAndLemonade.woff2') format('woff2'),
        url('./HorseshoesAndLemonade.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

